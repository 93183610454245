import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import Session from "supertokens-auth-react/recipe/session";

export function getApiDomain() {
  const apiPort = process.env.REACT_APP_API_PORT;
  const apiUrl = process.env.NODE_ENV === 'development' ? `${process.env.REACT_APP_API_DOMAIN}:${apiPort}` : `${process.env.REACT_APP_API_DOMAIN}`;
  return apiUrl;
}

export function getWebsiteDomain() {
  const websitePort = process.env.REACT_APP_WEBSITE_PORT;
  const websiteUrl = `${process.env.REACT_APP_WEBSITE_DOMAIN}:${websitePort}`;
  return websiteUrl;
}

export const SuperTokensConfig = {
  appInfo: {
    appName: "Mobito User Management",
    apiDomain: getApiDomain(),
    websiteDomain: getWebsiteDomain(),
  },
  // recipeList contains all the modules that you want to
  // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
  recipeList: [
    EmailPassword.init({
      signInAndUpFeature: {
        signInForm: {
          style: `
                        [data-supertokens~=headerSubtitle] {
                          display: none;
                        },
                        [data-supertokens~=superTokensBranding] {
                          display:none;
                        }
                    `,
        },
      },
    }),
    Session.init(),
  ],
};

export const PreBuiltUIList = [EmailPasswordPreBuiltUI];

export const ComponentWrapper = (props: {
  children: JSX.Element;
}): JSX.Element => {
  return props.children;
};
