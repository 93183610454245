import axios from "axios";
import { getApiDomain } from "../config";
import Modal from "./Modal";
import "./CallApiView.css";

import { useState, useEffect } from "react";

export default function CallAPIView() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiData, setApiData] = useState<any>(null);
  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(getApiDomain() + "/token");
        setApiData(JSON.stringify(response.data.token, null, 2));
      } catch (error) {
        console.error("Error fetching data:", error);
        setFetchError(true);
      }
    };

    fetchData();
  }, []);

  const openModal = () => {
    if (!fetchError) {
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="tooltip-container">
      <div
        className={fetchError ? "disabled" : "sessionButton"}
        onClick={openModal}
      >
        {!isModalOpen && <span>Get JWT</span>}
      </div>
      {fetchError && (
        <span className="tooltip">
          Failed to fetch data. Please try again later.
        </span>
      )}
      {isModalOpen && <Modal closeModal={closeModal} data={apiData} />}
    </div>
  );
}
