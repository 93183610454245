import React, { useEffect, useRef, useState } from "react";
import "./Modal.css";

interface ModalProps {
  closeModal: () => void;
  data: any;
}

const Modal: React.FC<ModalProps> = ({ closeModal, data }) => {
  const [copied, setCopied] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [copied]);

  const copyToClipboard = () => {
    if (textRef.current) {
      navigator.clipboard.writeText(textRef.current.innerText);
      setCopied(true);
    }
  };

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <div className="modal-content">
          <div className="modal-content-row">
            <p ref={textRef}>{data}</p>
            <span onClick={copyToClipboard}>
              <i className="material-icons">content_copy</i>
              {copied ? "Copied!" : "Copy to Clipboard"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;

